.container {
  text-align: center;

  .icon {
    margin-bottom: 15px;
  }

  .text {
    font-weight: 400;
    font-size: 24px;
  }
}
