.container {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 30px 30px 0 30px;

  a,
  span {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.7);

    &:hover {
      color: black;
      text-decoration: underline;
    }
  }
}
