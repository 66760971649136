.container {
  display: flex;
  flex-direction: column;
  padding: 25px;
  margin-left: auto;
  margin-right: auto;
  height: 95vh;
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
