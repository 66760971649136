.container {
  display: flex;
  justify-content: center;
  padding: 15px 0;
}

.text {
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.67);

  p:first-child {
    margin-right: 180px;
  }
}

@media (max-width: 480px) {
  .text {
    p:first-child {
      margin-right: 155px;
    }
  }
}
