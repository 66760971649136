@import "../../styles/colors.scss";

.btn {
  width: 100%;
  margin-top: auto;
  color: #fff;
  background-color: $btn-primary;
  border: 1px solid $btn-primary;
  padding: 10px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    background-color: $btn-primary-hover;
    border-color: $btn-primary-hover;
  }
}
