.img-container {
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;

  .img {
    width: 345px;
  }
}

@media (max-width: 480px) {
  .img-container {
    height: 375px;
  }
}
