@import "../../styles/colors.scss";

.score-input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 40px;
  color: $light-grey;
  border: 1px solid $light-grey;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:hover {
    cursor: pointer;
  }
}
